var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom Checkboxes" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustom) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("For cross browser consistency, ")]),
        _c("code", [_vm._v("<b-form-checkbox-group>")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("<b-form-checkbox>")]),
        _c("span", [
          _vm._v(
            " use Bootstrap's custom checkbox input to replace the browser default checkbox input. "
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { value: "A" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Checked ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: { value: "B" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Unchecked ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: { value: "C", disabled: "" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Checked disabled ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: { value: "D", disabled: "" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Unchecked disabled ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }