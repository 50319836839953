var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("form-checkbox-basic"),
          _c("form-checkbox-custom"),
          _c("form-checkbox-color"),
          _c("form-checkbox-multiple"),
          _c("form-checkbox-change-names"),
          _c("form-checkbox-stacked-inline"),
          _c("form-checkbox-states"),
          _c("form-checkbox-indeterminate")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }