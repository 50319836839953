var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Multiple choice checkboxes" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codemultipleChoice) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Using options array:" } },
        [
          _c("b-form-checkbox-group", {
            staticClass: "demo-inline-spacing",
            attrs: {
              id: "checkbox-group-1",
              options: _vm.options,
              name: "flavour-1"
            },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Using sub-components:" } },
        [
          _c(
            "b-form-checkbox-group",
            {
              staticClass: "demo-inline-spacing",
              attrs: { id: "checkbox-group-2", name: "flavour-2" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c("b-form-checkbox", { attrs: { value: "orange" } }, [
                _vm._v(" Orange ")
              ]),
              _c("b-form-checkbox", { attrs: { value: "apple" } }, [
                _vm._v(" Apple ")
              ]),
              _c("b-form-checkbox", { attrs: { value: "pineapple" } }, [
                _vm._v(" Pineapple ")
              ]),
              _c("b-form-checkbox", { attrs: { value: "grape" } }, [
                _vm._v(" Grape ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(" Selected: "),
        _c("strong", [_vm._v(_vm._s(_vm.selected))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }