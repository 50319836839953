var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Inline and stacked checkboxes" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInlineStacked) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Set the prop ")]),
        _c("code", [_vm._v("stacked")]),
        _c("span", [_vm._v(" on ")]),
        _c("code", [_vm._v("<b-form-checkbox-group>")]),
        _c("span", [
          _vm._v(
            " to place each form control one over the other, or if using individual checkboxes not inside a "
          )
        ]),
        _c("code", [_vm._v("<b-form-checkbox-group>")]),
        _c("span", [_vm._v(", set the ")]),
        _c("code", [_vm._v("inline")]),
        _c("span", [_vm._v(" prop on ")]),
        _c("code", [_vm._v("<b-form-checkbox>")]),
        _c("span", [_vm._v(".")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Form-checkbox-group inline checkboxes (default)"
              }
            },
            [
              _c("b-form-checkbox-group", {
                staticClass: "demo-inline-spacing",
                attrs: { options: _vm.options, name: "flavour-1a" },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Form-checkbox-group stacked checkboxes" } },
            [
              _c("b-form-checkbox-group", {
                staticClass: "demo-inline-spacing",
                attrs: {
                  options: _vm.options,
                  name: "flavour-2a",
                  stacked: ""
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Individual stacked checkboxes (default)" } },
            _vm._l(_vm.options, function(option) {
              return _c(
                "b-form-checkbox",
                {
                  key: option.value,
                  attrs: { value: option.value, name: "flavour-3a" },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [_vm._v(" " + _vm._s(option.text) + " ")]
              )
            }),
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Individual inline checkboxes" } },
            _vm._l(_vm.options, function(option) {
              return _c(
                "b-form-checkbox",
                {
                  key: option.value,
                  attrs: {
                    value: option.value,
                    name: "flavour-4a",
                    inline: ""
                  },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [_vm._v(" " + _vm._s(option.text) + " ")]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }