var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Changing the option field names" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeChangeNames) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "If you want to customize the field property names (for example using "
          )
        ]),
        _c("code", [_vm._v("name")]),
        _c("span", [_vm._v(" field for display ")]),
        _c("code", [_vm._v("text")]),
        _c("span", [_vm._v(" ) you can easily change them by setting the ")]),
        _c("code", [_vm._v("text-field")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("html-field")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("value-field")]),
        _c("span", [_vm._v(" , and ")]),
        _c("code", [_vm._v("disabled-field")]),
        _c("span", [
          _vm._v(
            " props to a string that contains the property name you would like to use:"
          )
        ])
      ]),
      _c("b-form-checkbox-group", {
        staticClass: "demo-inline-spacing",
        attrs: {
          options: _vm.options,
          "value-field": "item",
          "text-field": "name",
          "disabled-field": "notEnabled"
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
        _vm._v(" Selected: "),
        _c("strong", [_vm._v(_vm._s(_vm.selected))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }