var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Color" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColor) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("To change the color of the checkBox use the ")]),
        _c("code", [_vm._v(".custom-control-{value}")]),
        _c("span", [
          _vm._v(" for primary, secondary, success, danger, info, warning.")
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "custom-control-primary",
              attrs: { value: "A" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Primary ")]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "custom-control-secondary",
              attrs: { value: "CC" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Secondary ")]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "custom-control-success",
              attrs: { value: "C" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Success ")]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "custom-control-danger",
              attrs: { value: "F" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Danger ")]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "custom-control-warning",
              attrs: { value: "D" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Warning ")]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "custom-control-info",
              attrs: { value: "G" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Info ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }